export const dizhi='江西省宜春市樟树市福城街道四特大道1号'
export const gongsi='樟树市魔客点创科技有限公司'
export const gongsi2='Copyright © 2024 樟树市魔客点创科技有限公司'
export const dianhua='18679510393'

export const mixins = {
    data() {
      return {
        gongsi:gongsi,
        gongsi2:gongsi2,
        dianhua: dianhua,
        dizhi: dizhi,
        youxiang:'1318827509@qq.com',
        qq:'1318827509',
        // 首页底部
        // 赣公网安备 36070202000237号
        beianhao1: "",
        // 赣ICP备2024033839号-1
        beianhao2: "| 赣ICP备2024033839号-1",
        // 增值电信业务经营许可证 赣B2-20240340
        beianhao3: "| 增值电信业务经营许可证 赣B2-20240340",

      };
    },
  };